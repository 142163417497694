import { getRoutePathByItem } from "@constants";

// don't change THUMBNAILS name/type, it's used by `pages-loader` webpack loader
// see scripts/webpack/loaders/pages-loader.js
// prototype: device/name => thumbnail
const Campaign_THUMBNAILS = {
  "desktop/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFgAAAwAAAAAAAAAAAAAAAAAAAwQF/8QAIBABAAEDAwUAAAAAAAAAAAAAAQIAAxEEIVETFDFCYf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAESIf/aAAwDAQACEQMRAD8AWv24xsycRFDxEMbnFNMC0odzb6TFnvg34eaCgzvHuHzFS0LWX//Z",
  "desktop/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAX/xAAeEAACAQMFAAAAAAAAAAAAAAABAgADBDEREiFBUf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8ASyHaTqMSSZZstSsFYYXk9mBMCJ5Av//Z",
  "desktop/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAP/xAAgEAEAAgECBwAAAAAAAAAAAAABAgMAESEEBRIxQWGR/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/8QAGREAAwEBAQAAAAAAAAAAAAAAAAERITFB/9oADAMBAAIRAxEAPwAvBxY2xsd4o+fWFGejy10egYyOzrk3hLpE5hYm6/cw6OH/2Q==",
  "desktop/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAgMEBf/EAB8QAAICAQQDAAAAAAAAAAAAAAECAAMRBBIhMRNBsf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8AoJHDej9mWmffqbRYQp2gDrERFWnc2UK7dmFCtu4MASAxkUi6jyPlHxxjBESph+nUpQqkciB//9k=",
  "desktop/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwIE/8QAIxAAAQQBAgcBAAAAAAAAAAAAAQACAxEhBBITMTJicYGRsf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A2TycPa4kdQ5+1KsIX2M/iAnSUSAL8KCJdO6WINedubwmgNQSJMXSjQbd3fEH/9k=",
  "desktop/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAUC/8QAHhAAAgICAgMAAAAAAAAAAAAAAQIDIQAREjEEE1H/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAwDAQACEQMRAD8ATFK58R5GQ+wA0Rq9YhiKaSSFzKvEg1WskLGjMpIdQCT2ckrOwCNx13ma1AZ5W0QVrX3GiJtCit4F/9k=",
  "desktop/bottom-4": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAASABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABwQAAICAgMAAAAAAAAAAAAAAAABAhFhkRIhMf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDHUW/K2A+EsbQEgNOna6YFSm2quVZYEAMAAAP/2Q==",
  "mobile/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAwIEBf/EACIQAAIBAgUFAAAAAAAAAAAAAAECAAMRBBITIVEiI3Gh0f/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDJUKT1GwgSZKdti1/EAYD0NLMdV2TiyZr+4DM2HI3rueO0PsClA//Z",
  "mobile/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAwECBP/EAB8QAQABBAIDAQAAAAAAAAAAAAECABESMQMhE2GBof/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhEDEQA/ANDk6H4VIfNn1i423copg8Jvfk/KCfgSMBlJV2SdUhHLLIQt36oSpYAvUX//2Q==",
  "mobile/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQIE/8QAIRAAAgIBAwUBAAAAAAAAAAAAAQMCEQATMVEEEiGBkaH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8ALjEFgvnAY0FA+ExB57BgVpLO64/BgEpiGMAF3veS3Fk1ra96oWHX6GSXVsxI6p8hZZ+DLqY//9k=",
  "mobile/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAMF/8QAHxAAAgIBBAMAAAAAAAAAAAAAAQIAAyEREkFRBDGh/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwBBON3Y+zLTOv8AJtFjaNtwMYiIZUxepGPsgEwqS271YZAY6kSKlbQtrEq7LrxxEphNQK1IvQAgf//Z",
  "mobile/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQB/8QAHxAAAQQCAgMAAAAAAAAAAAAAAQACAxESITFhIpHB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwCueTBmV2QW/VKsIZBQyICAjKLNb7UGPgziLZDyQdIJ52uz8bodrOtDa19cH2g//9k=",
  "mobile/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAQQFA//EACAQAAICAgICAwAAAAAAAAAAAAECAxEAIRIxBEEiUXH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAwDAQACEQMRAD8A38SZ5XIljK0LGiLyoMU7yPIrR8QvR+94CBRnJZStX7OBa5pdjWj3mWiU0jj0K/ctIln4miBeQf/Z"
};

const getThumbnailByKey = imgPath => {
  const key = Object.keys(Campaign_THUMBNAILS).find(
    k => imgPath.indexOf(k) !== -1
  );

  return Campaign_THUMBNAILS[key] || false;
};

export default ({ imgParams, pathfinder, i18n }) => {
  const _i18n = i18n.pages.Campaign;

  const extractImage = src => {
    const re = new RegExp(`^v(\\d+)\\/${imgParams.cloudinary.path}\\/(.+)$`);

    const m = re.exec(src) || {};

    return { src: m[2], version: m[1] };
  };

  const buildItem = (item, props = {}, loading = null) => ({
    img: {
      ...extractImage(item.IMG || ""),
      thumbnail: getThumbnailByKey(item.IMG),
      fluid: true,
      width: "100%",
      height: "100%",
      title: item.TITLE,
      importance: "high",
      loading,
      cloudinary: imgParams.cloudinary,
      sizes: props.sizes,
      aspect: props.aspect || 1,
      video: Boolean(item.VIDEO)
    },
    href: getRoutePathByItem(pathfinder, item)
  });

  const filterItem = (item, defaultValue = true) =>
    "undefined" === typeof item.ENABLED ? defaultValue : Boolean(item.ENABLED);

  const buildButton = button => {
    const href = getRoutePathByItem(pathfinder, button);

    if (!button.TITLE || !button.ENABLED || !href) {
      return false;
    }

    return {
      button: {
        block: true,
        variant: button.VARIANT,
        className: "px-1",
        onClick: e => {},
        href: getRoutePathByItem(pathfinder, button)
      },
      title: button.TITLE,
      icon: {
        transform: "right-2",
        icon: "angle-right"
      }
    };
  };

  const sizes = {
    carousel: {
      mobile: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1207
        },
        aspect: 0.9149
      },
      desktop: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1424
        },
        aspect: 0.3581
      }
    },
    bottomImg: {
      mobile: {
        sizes: {
          mobilePortrait: 390,
          mobileLandscape: 473,
          tabletPortrait: 213,
          tabletLandscape: 293,
          desktop: 298
        },
        aspect: 0.959
      },
      desktop: {
        sizes: {
          mobilePortrait: 390,
          mobileLandscape: 473,
          tabletPortrait: 213,
          tabletLandscape: 293,
          desktop: 350
        },
        aspect: 0.7982
      }
    }
  };

  const header =
    _i18n.header.TITLE || _i18n.header.TEXT
      ? { title: _i18n.header.TITLE, text: _i18n.header.TEXT }
      : null;

  const images = ["carousel", "bottomImg"].reduce((carry, key) => {
    return Object.assign(carry, {
      [key]: ["mobile", "desktop"].reduce((carry, device) => {
        return Object.assign(carry, {
          [device]: _i18n[key][device]
            .filter(item => filterItem(item))
            .map((item, index) =>
              buildItem(
                item,
                sizes[key][device],
                key === "bottomImg" || !index ? "eager" : null
              )
            )
        });
      }, {})
    });
  }, {});

  const buttons = _i18n.buttons.map(buildButton).filter(Boolean);

  return {
    ...images,
    header,
    buttons
  };
};
